import React, { useEffect, useState } from "react";
import styles from "./component.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import footerLogo from "../assets/images/footer-logo.png";
import { fetchFooter } from "../util/fetch";

const Footer = ({ footerData }) => {
  const navigate = useNavigate();
  const length = Object.keys(footerData).length;
  const scrollTo = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 500);
  };
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.footer_main}>
            <div className={styles.quick_links}>
              <h2 className={styles.foot_heading}>Quick Links</h2>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? `${styles.links} ${styles.active}` : styles.links
                }
                onClick={() => scrollTo()}
              >
                <p className={styles.link_txt}>Home</p>
              </NavLink>
              <NavLink
                to="locations"
                className={({ isActive }) =>
                  isActive ? `${styles.links} ${styles.active}` : styles.links
                }
                onClick={() => scrollTo()}
              >
                <p className={styles.link_txt}>Locations</p>
              </NavLink>
              <NavLink
                to="our-story"
                className={({ isActive }) =>
                  isActive ? `${styles.links} ${styles.active}` : styles.links
                }
                onClick={() => scrollTo()}
              >
                <p className={styles.link_txt}>Our Story</p>
              </NavLink>
              <NavLink
                to="convenience"
                className={({ isActive }) =>
                  isActive ? `${styles.links} ${styles.active}` : styles.links
                }
                onClick={() => scrollTo()}
              >
                <p className={styles.link_txt}>Convenience</p>
              </NavLink>
              <NavLink
                to="community"
                className={({ isActive }) =>
                  isActive ? `${styles.links} ${styles.active}` : styles.links
                }
                onClick={() => scrollTo()}
              >
                {" "}
                <p className={styles.link_txt}>Community</p>
              </NavLink>
              <NavLink
                to="gascorp"
                className={({ isActive }) =>
                  isActive ? `${styles.links} ${styles.active}` : styles.links
                }
                onClick={() => scrollTo()}
              >
                <p className={styles.link_txt}>Gascorp</p>
              </NavLink>
              <NavLink
                to="fuel-1-card"
                className={({ isActive }) =>
                  isActive ? `${styles.links} ${styles.active}` : styles.links
                }
                onClick={() => scrollTo()}
              >
                <p className={styles.link_txt}>Fuel 1 Card</p>
              </NavLink>
              <NavLink
                to="fuels"
                className={({ isActive }) =>
                  isActive ? `${styles.links} ${styles.active}` : styles.links
                }
                onClick={() => scrollTo()}
              >
                <p className={styles.link_txt}>Fuels</p>
              </NavLink>
              <NavLink
                to="employment"
                className={({ isActive }) =>
                  isActive ? `${styles.links} ${styles.active}` : styles.links
                }
                onClick={() => scrollTo()}
              >
                <p className={styles.link_txt}>Employment</p>
              </NavLink>
              <NavLink
                to="contact-us"
                className={({ isActive }) =>
                  isActive ? `${styles.links} ${styles.active}` : styles.links
                }
                onClick={() => scrollTo()}
              >
                <p className={styles.link_txt}>Contact Us</p>
              </NavLink>
              
            </div>
            <div className={styles.get_in_touch}>
              <h2 className={styles.foot_heading}>Get In Touch With Us</h2>
              {length > 0 && (
                <>
                  <div>
                    <div className={styles.office_sec}>
                      <h3>Head Office</h3>
                      <p>{footerData.footer_address}</p>
                    </div>
                  </div>
                  <div>
                    <div className={styles.phone_sec}>
                      <h3>Phone</h3>
                      <p>
                        <a href={`tel:${footerData.footer_phone_number}`}>
                          {footerData.footer_phone_number}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className={styles.mail_sec}>
                      <h3>Email</h3>
                      <p>
                        <a href={`mailTo:${footerData.footer_email}`}>
                          {footerData.footer_email}
                        </a>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={styles.follow_us}>
              <h2>Follow Us On</h2>
              {length > 0 && (
                <>
                  <div>
                    <p className={styles.tiktok}>
                      <a
                        href={`${footerData.footer_tiktok_link}`}
                        target="_blank"
                      >
                        Tik Tok
                      </a>
                    </p>
                  </div>
                  <div>
                    <p className={styles.facebook}>
                      <a href={`${footerData.footer_fb_link}`} target="_blank">
                        Facebook
                      </a>
                    </p>
                  </div>
                  <div>
                    <p className={styles.insta}>
                      <a
                        href={`${footerData.footer_insta_link}`}
                        target="_blank"
                      >
                        Instagram
                      </a>
                    </p>
                  </div>
                  <div>
                    <p className={styles.linkedin}>
                      <a
                        href={`${footerData.footer_linkein_link}`}
                        target="_blank"
                      >
                        Linkedin
                      </a>
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className={styles.footer_logo}>
              <NavLink to="/" onClick={() => scrollTo()}>
                <img src={footerLogo} />
              </NavLink>
            </div>
          </div>
        </div>
        <div className={styles.footer_copy}>
          <div className={styles.container}>
            {length > 0 && (
              <p className={styles.copyright_text}>
                {footerData.footer_copyright.slice(0, 15)}
                <NavLink to="/" onClick={()=>scrollTo()}>{footerData.footer_copyright.slice(15, 22)}</NavLink>
                {footerData.footer_copyright.slice(22)}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
