export const GOOGLE_MAPS_API_KEY = "AIzaSyCAzgpUV-ps2T8qgcr0W_udawiuQwKDCvc";
// export const GOOGLE_MAPS_API_KEY = "AIzaSyCW_bQYvkCqKy_BHRdhMAnNnmvpVXEGUnM";
export const GEOCODE_API_KEY = "AIzaSyCirqB5P1WQkJx6vQLuKRIwI1jsPrURCbk";
// export const SITE_URL = "https://gascorp.com.au/"; //live
// export const SITE_URL = "https://www.budgetpetrol.vjbtestwebsites.com/"; //test
export const SITE_URL = "https://beta.gascorp.com.au/"; //beta
// export const BASE_URL = "https://gascorp.com.au/backend/api/"; //live
// export const BASE_URL = "https://www.budgetpetrol.vjbtestwebsites.com/backend/api/"; //test
export const BASE_URL = "https://beta.gascorp.com.au/backend/api/" //beta
// export const SITE_KEY = "6Le9k9cpAAAAALb3qxy2XfqM_OmeRPRDW6vy3PLw"; //test
export const SITE_KEY = "6LdxOxEqAAAAAN3FN0ZoIzVZPi1Ma3uU4-bgpRxX"; //beta
// export const SITE_KEY = "6Le_HwkqAAAAAOwuBQ1Odfwn4CCNjPPEQ9m4AIA-"; //live
// export const SECRET_KEY = "6Le9k9cpAAAAAPqLFmwuZDHB-MCkM-zcP4nAu53m"; //test
export const SECRET_KEY = "6LdxOxEqAAAAAD0d7UISNzHEkCe4j7VN07FchGwm" //beta
// export const SECRET_KEY = "6Le_HwkqAAAAAKwLrk3ZC2ak0bWbSoM_Js5NoUWi"; //live
// export const VIDEO_URL = "https://gascorp.com.au/backend/uploads"; //live
// export const VIDEO_URL = "https://www.budgetpetrol.vjbtestwebsites.com/backend/uploads"; //test
export const VIDEO_URL = "https://beta.gascorp.com.au/backend/uploads"; //beta