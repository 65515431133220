import React, { useEffect, useState } from "react";
import { fetchEmployment } from "../util/fetch";
import styles from "./pages.module.css";
import { NavLink } from "react-router-dom";
import { SITE_URL } from "../config/config";
import { Helmet } from "react-helmet";

const Employment = () => {
  const [employmentData, setEmploymentData] = useState({
    bannerData: {},
    employment: [],
  });
  useEffect(() => {
    document.title = "Employment | Budget Petrol";
    const fetchData = async () => {
      const response = await fetchEmployment();
      setEmploymentData({
        ...employmentData,
        bannerData: response.data.banner,
        employment: response.data.employment,
      });
    };
    fetchData();
  }, []);
  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Employment | Budget Petrol" />
        <meta property="og:url" content={`${SITE_URL}employment`} />
        <meta property="og:site_name" content="BUDGET PETROL" />
        <meta property="article:modified_time" content="2024-05-22T06:01:40+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="2 minutes" />
      </Helmet>
      {/* Banner Section */}
      <div
        className={styles.inner_banner_section}
        style={{
          backgroundImage: `url(${employmentData.bannerData.banner_image})`,
        }}
      >
        <div className={styles.container}>
          <div className={styles.banner_heading}>
            <h1 className={styles.banner_txt}>
              {employmentData.bannerData.banner_title}
            </h1>
            <div className={styles.breadcrumb_div}>
              <p>
                <NavLink to="/">Home </NavLink>
                <span>Employment</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Main Section */}
      <div className={styles.employment_main_sec}>
        <div className={styles.container}>
          <div className={styles.employment_heading}>
            <h2 className={styles.heading_txt}>Join <span>The Team</span></h2>
            <p>
              Fuel Your Career with Budget Petrol! At Budget Petrol, we’re not
              just about filling up tanks, we’re about fueling opportunities.
              Our commitment to excellence, integrity, and community sets us
              apart. Joining our team means being part of a family that values
              teamwork, growth, and customer service above all else. Discover
              fulfilling career opportunities with Budget Petrol today!
            </p>
          </div>
          <div className={styles.emp_main}>
            {employmentData.employment.length > 0 &&
              employmentData.employment.map((item) => (
                <div className={styles.posting_sec}>
                  <h3>{item.employment_title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.employment_content,
                    }}
                  ></p>
                  <a href={`${item.employment_link}`} target="_blank">
                    <button>Apply With Seek</button>
                  </a>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Employment;
